<template>
  <v-row>
    <v-col :lg="this.columnLeft" :md="this.columnLeft">
      <v-skeleton-loader :loading="isSkeletonLoading" height="600" type="table">
        <v-row>
          <v-col :lg="this.columnLeft"> </v-col>
          <v-col :lg="this.columnRight"> </v-col>
        </v-row>
        <v-data-table
          class="patient-list-page"
          item-key="name"
          :headers="headers"
          :items="items"
          :search="search"
          :options.sync="options"
          :server-items-length="totals"
          :footer-props="tableFooterProps"
          @update:items-per-page="renderPatientList"
          @update:page="onChangePagination"
        >
          <template v-slot:no-data>
            Search patients using search textbox
          </template>
          <template v-slot:top>
            <SearchPatientOptions
              v-model="searchOptions"
              @input="renderPatientList"
            />
          </template>

          <template v-slot:[`item.fullName`]="{ item }">
            <a @click="viewPatientInfo(item)">{{ item.fullName }}</a>
          </template>

          <template v-slot:[`item.pastServices`]="{ item }">
            <div class="d-flex flex-column">
              <span v-if="!item.pastServices.length">
                <b>N/A</b>
              </span>
              <a v-else @click="viewPatientLastVisit(item)"> View visits </a>
            </div>
          </template>

          <template v-slot:[`item.genderType`]="{ item }">
            <div class="d-flex flex-column">
              <GenderItem :genderType="item.genderType" />
            </div>
          </template>

          <template v-slot:[`item.dob`]="{ item }">
            {{ formatDate(item.dob) }}
          </template>
        </v-data-table>
      </v-skeleton-loader>
    </v-col>
    <v-col :lg="this.columnRight" :md="this.columnRight">
      <div class="patient-list-service">
        <v-card class="card-last-visit elevation-1">
          <PatientLastVisits
            ref="PatientLastVisits"
            :typeTarget="AuditType.Procedure"
          />
        </v-card>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { TABLE_FOOTER_PROPS, AuditType } from "@/plugins/constant";
import PatientService from "@/services/patient";
import GenderItem from "@/components/GenderItem";
import moment from "moment";
import PatientLastVisits from "@/components/common/Patient/components/PatientLastVisits";
import SearchPatientOptions from "@/components/common/Patient/SearchPatientOptions";
import i18n from "@/plugins/i18n";

const HEADER_PATIENT = [
  {
    text: i18n.t("main.patient.patientID"),
    align: "center",
    sortable: false,
    value: "patientID",
  },
  {
    text: i18n.t("main.patient.patientName"),
    align: "center",
    sortable: false,
    value: "fullName",
  },
  {
    text: i18n.t("main.patient.gender"),
    align: "center",
    sortable: false,
    value: "genderType",
  },
  {
    text: i18n.t("main.patient.dateOfBirth"),
    align: "center",
    sortable: false,
    value: "dob",
  },
  {
    text: i18n.t("main.patient.email"),
    align: "start",
    sortable: false,
    value: "email",
  },
  {
    text: i18n.t("main.patient.phone"),
    align: "start",
    sortable: false,
    value: "phoneNumber",
  },
  {
    text: i18n.t("personalInfo.address"),
    align: "center",
    sortable: false,
    value: "address",
    width: "30%"
  },
  {
    text: i18n.t("main.patient.lastVisits"),
    align: "center",
    sortable: false,
    value: "pastServices",
  },
];

export default {
  name: "PatientPage",
  components: {
    GenderItem,
    PatientLastVisits,
    SearchPatientOptions,
  },
  data() {
    return {
      AuditType,
      columnLeft: 12,
      columnRight: 0,
      isSkeletonLoading: false,
      search: "",
      options: {
        page: 1,
        itemsPerPage: 20,
      },
      totals: 0,
      items: [],
      headers: HEADER_PATIENT,
      tableFooterProps: TABLE_FOOTER_PROPS,
      searchOptions: {
        keySearch: "",
        idSearch: "",
        aidSearch: "",
        phoneSearch: "",
        dobSearch: "",
        address: "",
      },
    };
  },
  async mounted() {},
  methods: {
    showError(message) {
      this.$toast.error(message);
    },
    formatDate(date) {
      if (!date) return "";
      return moment(date).format("DD/MM/YYYY");
    },
    viewPatientInfo(item) {
      var { patientID, fullName } = item;
      var key = `/patient/${patientID}`;
      var label = fullName;
      var tabData = {
        key,
        label,
      };
      parent.postMessage({ event: "ADD_HEADER_TAB", data: tabData }, "*");
    },
    viewPatientLastVisit(item) {
      var { patientID, fullName } = item;
      this.$refs.PatientLastVisits.renderLastVisits(patientID, fullName);
      (this.columnLeft = 7), (this.columnRight = 5);
    },
    async renderPatientList() {
      this.isSkeletonLoading = true;
      const { page, itemsPerPage } = this.options;
      const {
        keySearch,
        idSearch,
        aidSearch,
        phoneSearch,
        dobSearch,
        addressSearch,
      } = this.searchOptions;
      var result = await PatientService.searchPatientsWithPastServices(
        keySearch,
        idSearch,
        aidSearch,
        phoneSearch,
        dobSearch,
        addressSearch,
        page,
        itemsPerPage
      );
      console.log(result)
      this.isSkeletonLoading = false;
      if (!result || result.error) {
        this.showError("Cannot get patient list! Please try again later!");
        return;
      }
      var { items, totals } = result;
      items.forEach((item) => {
        for (var key in item) {
          if (!item[key]) {
            item[key] = "N/A";
          }
        }
      });
      this.totals = totals;
      this.items = items;
    },
    async onChangePagination(value) {
      this.options.page = value;
      await this.renderPatientList();
    },
  },
};
</script>

<style lang="scss">
.patient-list-page {
  margin-top: 24px;
}
.card-last-visit {
  min-width: 30%;
  max-width: 36%;
}
.patient-list-service {
  position: fixed;
  width: 100%;
  margin: 5% auto;
  padding: 10px;
}
</style>