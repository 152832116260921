var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"lg":this.columnLeft,"md":this.columnLeft}},[_c('v-skeleton-loader',{attrs:{"loading":_vm.isSkeletonLoading,"height":"600","type":"table"}},[_c('v-row',[_c('v-col',{attrs:{"lg":this.columnLeft}}),_c('v-col',{attrs:{"lg":this.columnRight}})],1),_c('v-data-table',{staticClass:"patient-list-page",attrs:{"item-key":"name","headers":_vm.headers,"items":_vm.items,"search":_vm.search,"options":_vm.options,"server-items-length":_vm.totals,"footer-props":_vm.tableFooterProps},on:{"update:options":function($event){_vm.options=$event},"update:items-per-page":_vm.renderPatientList,"update:page":_vm.onChangePagination},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" Search patients using search textbox ")]},proxy:true},{key:"top",fn:function(){return [_c('SearchPatientOptions',{on:{"input":_vm.renderPatientList},model:{value:(_vm.searchOptions),callback:function ($$v) {_vm.searchOptions=$$v},expression:"searchOptions"}})]},proxy:true},{key:"item.fullName",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.viewPatientInfo(item)}}},[_vm._v(_vm._s(item.fullName))])]}},{key:"item.pastServices",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[(!item.pastServices.length)?_c('span',[_c('b',[_vm._v("N/A")])]):_c('a',{on:{"click":function($event){return _vm.viewPatientLastVisit(item)}}},[_vm._v(" View visits ")])])]}},{key:"item.genderType",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('GenderItem',{attrs:{"genderType":item.genderType}})],1)]}},{key:"item.dob",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.dob))+" ")]}}],null,true)})],1)],1),_c('v-col',{attrs:{"lg":this.columnRight,"md":this.columnRight}},[_c('div',{staticClass:"patient-list-service"},[_c('v-card',{staticClass:"card-last-visit elevation-1"},[_c('PatientLastVisits',{ref:"PatientLastVisits",attrs:{"typeTarget":_vm.AuditType.Procedure}})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }